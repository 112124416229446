// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cennik-js": () => import("/opt/build/repo/src/pages/cennik.js" /* webpackChunkName: "component---src-pages-cennik-js" */),
  "component---src-pages-dofinansowanie-tsx": () => import("/opt/build/repo/src/pages/dofinansowanie.tsx" /* webpackChunkName: "component---src-pages-dofinansowanie-tsx" */),
  "component---src-pages-filmy-wprowadzajace-tsx": () => import("/opt/build/repo/src/pages/filmy-wprowadzajace.tsx" /* webpackChunkName: "component---src-pages-filmy-wprowadzajace-tsx" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-podziekowanie-js": () => import("/opt/build/repo/src/pages/kontakt-podziekowanie.js" /* webpackChunkName: "component---src-pages-kontakt-podziekowanie-js" */),
  "component---src-pages-kontakt-js": () => import("/opt/build/repo/src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-podziekowanie-za-zapis-do-newslettera-js": () => import("/opt/build/repo/src/pages/podziekowanie-za-zapis-do-newslettera.js" /* webpackChunkName: "component---src-pages-podziekowanie-za-zapis-do-newslettera-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("/opt/build/repo/src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-regulamin-js": () => import("/opt/build/repo/src/pages/regulamin.js" /* webpackChunkName: "component---src-pages-regulamin-js" */),
  "component---src-pages-standardy-bezpieczenstwa-js": () => import("/opt/build/repo/src/pages/standardy-bezpieczenstwa.js" /* webpackChunkName: "component---src-pages-standardy-bezpieczenstwa-js" */),
  "component---src-pages-wystaw-fakture-js": () => import("/opt/build/repo/src/pages/wystaw-fakture.js" /* webpackChunkName: "component---src-pages-wystaw-fakture-js" */)
}

