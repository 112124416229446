module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Program do fakturowania - FAKTURUJ.TO","short_name":"FAKTURUJ.TO","start_url":"/","background_color":"#fafafa","theme_color":"#D8232A","display":"minimal-ui","icon":"/opt/build/repo/static/images/icon.png"},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
